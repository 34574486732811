<template>
  <div class="page product-apply">
    <div class="container">
      <van-form ref="applyForm" @submit="onSubmit">
        <van-cell-group inset>
          <h3>订单信息</h3>
          <van-cell :border="false">
            <template #title>
              <span>订单号：</span>{{ payOrderInfo.mchOrderNo }}
            </template>
          </van-cell>

          <van-cell :border="false">
            <template #title>
              <span>下单时间：</span>{{ payOrderInfo.createdAt }}
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group inset>
          <h3>选择支付方式</h3>
          <van-radio-group v-model="payOrderInfo.curWayCode">
            <van-cell-group
              class="paytype-radio-group"
              :border="false"
              v-if="
                (sysPayWay == 'H5' || sysPayWay == 'WX_H5') && showAuth('WX_H5')
              "
            >
              <van-cell
                title="微信支付"
                clickable
                @click="chooseWayCode('WX_H5')"
              >
                <van-icon
                  size="24"
                  name="wechat-pay"
                  class="radio-icon"
                  color="#09bb07"
                />
                <template #right-icon>
                  <van-radio
                    name="WX_H5"
                    :disabled="
                      payOrderInfo.wayCode == 'QR_CASHIER' ? false : true
                    "
                  />
                </template>
              </van-cell>
            </van-cell-group>

            <van-cell-group
              class="paytype-radio-group"
              :border="false"
              v-if="
                (sysPayWay == 'H5' || sysPayWay == 'ALI_H5') &&
                showAuth('ALI_H5')
              "
            >
              <van-cell
                title="支付宝支付"
                clickable
                @click="chooseWayCode('ALI_H5')"
              >
                <van-icon
                  size="20"
                  :name="require('@/assets/images/zfb.jpeg')"
                  class="radio-icon"
                  color="#2196f3"
                />
                <template #right-icon>
                  <van-radio
                    name="ALI_H5"
                    :disabled="
                      payOrderInfo.wayCode == 'QR_CASHIER' ? false : true
                    "
                  />
                </template>
              </van-cell>
            </van-cell-group>

            <van-cell-group
              class="paytype-radio-group"
              :border="false"
              v-if="sysPayWay == 'H5' && showAuth('YSF_H5')"
            >
              <van-cell
                title="云闪付支付"
                clickable
                @click="chooseWayCode('YSF_H5')"
              >
                <van-icon
                  size="20"
                  :name="require('@/assets/images/ysf.jpg')"
                  class="radio-icon"
                  color="#09bb07"
                />
                <template #right-icon>
                  <van-radio
                    name="YSF_H5"
                    :disabled="
                      payOrderInfo.wayCode == 'QR_CASHIER' ? false : true
                    "
                  />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
          <div class="cue" v-if="payOrderInfo.wayCode != 'QR_CASHIER'">
            支付订单已经创建，不允许切换支付方式
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <div class="end">
      <div class="settle">
        <van-button
          native-type="submit"
          type="info"
          round
          class="settle-submit"
          @click="onSubmit"
          :loading="loading"
          >确认支付</van-button
        >
        支付金额：<strong>{{ payOrderInfo.amount / 100 }}元</strong>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import config from "@/config";
import { getPayOrderInfoById, payOrderPassage, getPay } from "@/api/api";

export default {
  data() {
    return {
      payOrderId: "",
      // 订单详情+this.wxCode
      payOrderInfo: {},
      payType: "1",
      // 获取支付参数
      resData: {},
      loading: false,
      sysPayWay: "",
      payWayList: ["WX_H5", "ALI_H5", "YSF_H5"],
      passageList: [],
    };
  },
  props: {},
  created() {},
  mounted() {
    this.sysPayWay = this.getSysPayWay();
    let { payOrderId } = this.$route.query;

    if (payOrderId) {
      this.payOrderId = payOrderId;
      this.payOrderPassage();
    }
    document.querySelector("#app").style.height = window.innerHeight + "px";
  },
  computed: {},
  components: {},
  methods: {
    getPayOrderInfo() {
      const that = this;
      getPayOrderInfoById(this.payOrderId)
        .then((res) => {
          //alert(JSON.stringify(res));
          let curWayCode = "";
          for (let i = 0; i < this.payWayList.length; i++) {
            if (
              (this.sysPayWay == "H5" ||
                this.sysPayWay == this.payWayList[i]) &&
              this.showAuth(this.payWayList[i])
            ) {
              if (res.wayCode == "QR_CASHIER") {
                curWayCode = this.payWayList[i];
                break;
              } else {
                if (res.wayCode.indexOf(this.payWayList[i]) >= 0) {
                  curWayCode = this.payWayList[i];
                  break;
                }
              }
            }
          }
          res.curWayCode = curWayCode;
          that.payOrderInfo = res;
          this.$forceUpdate();
        })
        .catch((res) => {
          Toast({
            message: res.msg,
            duration: 2000,
          });
        });
    },
    payOrderPassage() {
      const that = this;
      payOrderPassage(this.payOrderId).then((res) => {
        this.passageList = res;
        that.getPayOrderInfo();
      });
    },
    chooseWayCode(wayCode) {
      if (this.payOrderInfo.wayCode == "QR_CASHIER") {
        this.payOrderInfo.curWayCode = wayCode;
      }
    },
    onSubmit() {
      this.pay();
    },
    // 支付事件
    pay: function () {
      if (!this.payOrderInfo.curWayCode) {
        Toast({
          message: "暂无支持的支付方式！",
          duration: 2000,
        });
        return false;
      }
      // 该函数执行效果慢
      let that = this;
      this.loading = true;
      let params = {
        amount: this.payOrderInfo.amount,
        wayCode: this.payOrderInfo.curWayCode,
        payOrderId: this.payOrderInfo.payOrderId,
      };
      getPay(params)
        .then((res) => {
          //alert(JSON.stringify(res));
          this.loading = false;
          window.location.replace(res.data.payUrl);
        })
        .catch((res) => {
          this.loading = false;
          Toast({
            message: res.data.errMsg,
            duration: 2000,
          });
          return;
        });
    },
    showAuth(parameterA) {
      if (this.passageList.toString().indexOf(parameterA) === -1) {
        return false;
      } else {
        return true;
      }
    },
    getSysPayWay: function () {
      const userAgent = navigator.userAgent;
      alert(JSON.stringify(userAgent));
      if (userAgent.indexOf("MicroMessenger") >= 0) {
        return "WX_H5";
      }
      if (userAgent.indexOf("AlipayClient") >= 0) {
        return "ALI_H5";
      }
      if (this.brower()) {
        return "H5";
      }

      return null;
    },
    brower: function () {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      if (userAgent.indexOf("Opera") > -1) {
        return true;
      } //判断是否Opera浏览器
      if (userAgent.indexOf("Firefox") > -1) {
        return true;
      } //判断是否Firefox浏览器
      if (userAgent.indexOf("Chrome") > -1) {
        return true;
      }
      if (userAgent.indexOf("Safari") > -1) {
        return true;
      } //判断是否Safari浏览器
      if (
        userAgent.indexOf("compatible") > -1 &&
        userAgent.indexOf("MSIE") > -1 &&
        !isOpera
      ) {
        return true;
      } //判断是否IE浏览器
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: #f8f9fb;
}
.product-apply {
  height: 100%;
}
.container {
  overflow: auto;
  color: #111a34;
  .van-cell-group--inset,
  .insureAdd {
    margin: 10px 16px;
  }
  .insureAdd {
    margin: 0 16px 16px;
    width: 343px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    color: #1e92ff;
  }
  h3 {
    margin: 12px 0 14px 14px;
    line-height: 27px;
    font-size: 18px;
    color: #121130;
    em {
      position: absolute;
      margin-top: 4px;
      margin-left: 5px;
      display: inline-block;
      min-width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #fff;
      background-color: #1e92ff;
      border-radius: 9px;
    }
  }
  p {
    line-height: 24px;
    font-size: 14px;
  }
}
// 尾部固定
.end {
  position: fixed;
  z-index: 9;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  bottom: 0;
}
.cue {
  font-size: 12px;
  color: red;
  padding: 10px 20px;
}
.settle,
.settle-submit {
  line-height: 40px;
  overflow: hidden;
}
.settle {
  width: 100%;
  height: 72px;
  padding: 14px 16px 14px 20px;
  font-size: 14px;
  box-sizing: border-box;
  strong {
    font-size: 16px;
    color: #f00;
  }
  .settle-submit {
    float: right;
    height: 40px;
  }
  .van-button__text {
    padding: 0 10px;
  }
}
.paytype-radio-group {
  padding: 5px 0;
  .van-cell__value {
    position: absolute;
  }
  .van-cell__title {
    padding-left: 30px;
    font-size: 16px;
  }
}
</style>
